<template>
	<div class="medical-records">

		<div class="visitRecord">
			<div class="list-container">
				<div class="container-item" v-for="(item, index) in visitRecordList" :key="index">
					<div class="clinic">
						<div class="clinic-head" :class="{'hosp-head':item.type == 137002}">
							<i class="icon"></i>
							<span class="txt">{{item.type == 137002? $t("patient.hospitalization"):$t("patient.clinic")}}</span>
						</div>
						<div class="clinic-info">
							<div class="info-txt">
								<p class="txt" v-if="item.type == 137001">{{$t("patient.clinicTime",{time:getLocalMinutesTime(item.cdt)})}}</p>
								<p class="txt" v-if="item.type == 137002">{{$t("patient.hosptime")}}：{{getLocalMinutesTime(item.inHospitalDt)}}</p>
								<p class="txt" v-if="item.type == 137002">{{$t("patient.leavehosp")}}：{{item.outHospitalDt?getLocalMinutesTime(item.outHospitalDt):$t("patient.inhosp")}}</p>
								<p class="txt">{{$t("patient.patientNum",{num:item.caseCount})}}</p>
							</div>
							<div class="info-btn" @click="goDetail(item)">
								{{$t("patient.seeDetails")}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="list-empty" v-if="visitRecordList.length == 0">
				<img src="@/assets/img/visitrecord-emtry.png" alt="">
			</div>
		</div>

	</div>
</template>

<script>
import { patientDelete } from "@/api/patient";
import { getPatientInfo, medicalQuery, hospAdd,  hospRecord} from "@/api/patient"
import { getLocalMinutesTime } from "@/utils/date";
import imageData from "./imageData.vue"
export default {
	components:{imageData},
	data(){
		return{
			infoData:{},
			visitRecordList:[],
			hospType:false,
			patientId:this.$route.query.patientId,
			getLocalMinutesTime
		}
	},
	created(){
		document.title = this.$t("patient.patientList")
		this.getPatientQuery();
	},
	methods:{

		seeImsge(){
			this.$router.push({
				path:"/patient/imageData",
				query:{
					patientId:this.patientId
				}
			})
		},
		// 查询住院记录
		getHospRecord(){
			hospRecord({
				patientId:this.$route.query.patientId
			}).then(res=>{
				if(res.data.data){
					this.hospType = true;
				}
			})
		},
		handleHosp(){
			const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
			hospAdd({
				patientId:this.patientId
			}).then(res=>{
				toast.clear();
				if(res.data.errorCode == 0){
					this.hospType = true;
				}
			})
		},

		// 查看就诊详情
		goDetail(item){
			this.$router.push({
				path:"/patient/medicalRecordsDetails",
				query:{
					medicalId:item.id
				},
			})
		},
		// 获取就诊记录
		async getPatientQuery(){
			let result = await medicalQuery({
				patientId:this.$route.query.patientId,
				pageSize:10000,
				pageNum:1,
			})
			if(result.data.errorCode == 0){
				this.visitRecordList = result.data.data.data;
			}
		},

		gopath(){
			this.$router.push({
                path:"/patient/edit",
                query:{
                    patientId:this.$route.query.patientId
                }
            })
		}
	}
}
</script>

<style lang="scss" scoped>

.medical-records{
  	min-height: 100%;
	background: #F5F6F9;
	height: max-content;
	overflow: auto;
	box-sizing: border-box;
	padding:  0 0.6rem;
	p{
		margin: initial;
	}

	.visitRecord{
		margin-top: 1rem;
		.title{
			font-size: 0.72rem;
			font-family: PingFang SC;
			font-weight: 600;
			color: #333333;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			span{
				position: relative;
				z-index: 11;
			}
			.more-btn{
				font-size: 0.48rem;
				font-family: PingFang SC;
				font-weight: 400;
				color: #999999;
			}
			&::after{
				content: " ";
				position: absolute;
				z-index: 1;
				left: 0;
				bottom: 0;
				width: 2rem;
				height: 0.44rem;
				background: linear-gradient(90deg, #90D3FF 0%, #F5F6FA 100%);
			}
		}
		.list-container{
			margin-top: 0.3rem;
			.container-item{
				border-radius: 0.32rem;
				overflow: hidden;
				background: #FFFFFF;
				margin-bottom: 0.6rem;
				.clinic{
					.clinic-head{
						height: 1.8rem;
						padding: 0 0.6rem;
						display: flex;
						align-items: center;
						background: linear-gradient(270deg, #F1FCFF 0%, #E7FEFF 100%);
						.icon{
							width: 0.76rem;
							height: 0.76rem;
							margin-right: 0.3rem;
							background: url("~@/assets/img/clinic-icon.png");
							background-size: 100% 100%;
						}
						.txt{
							font-size: 0.64rem;
							font-family: PingFang SC;
							font-weight: 600;
							color: #333333;
						}
					}
					.hosp-head{
						background: linear-gradient(270deg, #EFF8FF 0%, #CDE9FF 100%);
						.icon{
							background-image: url("~@/assets/img/hosp-head-icon.png");
						}
					}
					.clinic-info{
						display: flex;
						padding: 0.6rem;
						box-sizing: border-box;
						align-items: center;
						.info-txt{
							flex: 1;
							.txt{
								font-size: 0.56rem;
								font-family: PingFang SC;
								font-weight: 400;
								color: #666666;
								margin-bottom: 0.44rem;
								&:last-child{
									margin: 0;
								}
							}
						}
						.info-btn{
							flex: 0 0 auto;
							margin-left: 0.4rem;
							font-size: 0.56rem;
							font-family: PingFang SC;
							font-weight: 400;
							color: #FFFFFF;
							background: linear-gradient(0deg, #499FFF 0%, #49BFFF 100%);
							padding: 0.24rem 0.6rem;
							border-radius: 0.6rem;
						}
					}
				}
			}
		}
	}
}
</style>
<style lang="scss">
.list-empty{
	height: 100vh;
	// background: #fff;
	margin-top: 0.6rem;
	border-radius: 0.4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	img{
		height: 4rem;
	}
}
 .unbindconfrim{
	 width: 70%;
	 .van-dialog__message {
		font-size: 0.64rem;
		color: #333333;
		height: auto;
		line-height: unset;
		padding-top: 0.4rem;
	}
	.van-dialog__header{
		font-weight: 600;
		font-size: 0.7rem;
	}
	.van-dialog__cancel, .van-dialog__confirm{
		height: 1.2rem;
	}
}
</style>