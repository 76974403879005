<template>
    <div class="visitRecord">
        <p class="title">
            <span>{{$t("patient.ImageData")}}</span>
            <span class="more-btn" v-if="ImageList.length > 1">
                {{$t("patient.more")}}
            </span>
        </p>
        <div class="imageData-main">
            <div class="progress-bar">
                <div class="bar-item itemActive flex " v-for="(item,index) in ImageList" :key="index" v-if="index<1">
                    <div class="item-left-line">
                        <span>{{index+1}}</span>
                    </div>
                    <div class="item-right-container">
                        <p class="time">{{item.studyDt}}</p>
                        <p class="time">{{item.tenantName}}</p>
                        <div class="container-details executed">
                            <img :src="item.icoUrl" @click="goPath(item.dicomUrl)" class="thumbnail" alt="">
                        </div>
                    </div>
                </div>
                <div class="list-empty" v-if="ImageList.length == 0">
                    <img src="@/assets/img/visitrecord-emtry.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { dicomQuery } from "@/api/patient";
export default {
    props:{
        patientId:{
            type:String,
        }
    },
    data(){
        return{
            ImageList:[]
        }
    },
    created(){
        this.getDiccomData()
    },
    mounted(){

    },
    methods:{
        getDiccomData(){
            dicomQuery({
                patientId:this.patientId
            }).then(res=>{
                console.log(res);
                if(res.data.errorCode == 0){
                    if(res.data.data.length!=0){
                        this.ImageList = res.data.data[0];
                    }
                }
            })
        },
        goPath(url){
            if (url) {
                window.location.href = url;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.imageData-main{
    //  min-height: 100vh;
    background-color: #F5F6FA;
    box-sizing: border-box;
    padding-top: 0.2rem;
    .progress-bar{
        padding: 0.6rem 0;
        .bar-item{
            // padding-left: 1.2rem;
            padding-bottom: 1rem;
            align-items: flex-start;
            position: relative;
            
            &:last-child{
                padding-bottom: 0;
            }
            &::after{
                content: ' ';
                position: absolute;
                height: 100%;
                top: 0;
                left: 0.58rem;
                border-left:0.04rem solid #BFBFBF;
                z-index: 1;
            }
            .item-left-line{
                flex: 0 0 auto;
                width: 1.2rem;
                height: 100%;
                align-items: center;
                justify-content: center;
                display: flex;
                
                span{
                    position: relative;
                    z-index: 9;
                    width: 0.6rem;
                    height: 0.6rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    border-radius: 50%;
                    color: #FFFFFF;
                    background: #BFBFBF;
                }
            }
            .item-right-container{
                flex: 1;
                .today{
                    font-weight: 500;
                    color: #FF671C !important;
                    span{
                        font-size: 0.72rem;
                    }
                }
                .time{
                    font-size: 0.56rem;
                    line-height: 0.8rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #999999;
                }
                .container-details{
                    // padding: 0.6rem;
                    background: #ECEEF3;
                    box-shadow: 0px 0.12rem 0.3rem 0px rgba(197, 215, 232, 0.25);
                    border-radius: 0.16rem;
                    margin-top: 0.2rem;
                    position: relative;
                    width: max-content;
                    .thumbnail{
                        height: 4rem;
                        width: auto;
                        max-width: 100%;
                        border-radius: 0.3rem;
                    }
                    
                }
                .executed{
                    background: #fff;
                }
                .nowCurrent{
                    background: #3387FD;
                    p,span{
                        color: #fff !important;
                    }

                }
            }
        }
        .itemActive{
            &::after{
                border-color: #3387FD;
            }
            .item-left-line{
                span{
                    background: #3387FD;
                }
            }
        }
    }
}
</style>